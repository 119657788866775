@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-margin-top: 67.5px;
  box-sizing: border-box;
}

html, body , #root {
  font-size: 10px;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}
